import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import AliceCarousel from "react-alice-carousel";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Ride with Wind</h4>
  <h5>A playful experience for Lego House outdoor zone</h5>
  <br />
  <br />
  2022 <br />
  Individual
  <br />
  Collaboration:
  <a href="https://legohouse.com/da-dk/"> Lego House</a>, Denmark
  <br />
  Keywords: learning through play, play with nature, 3D modelling, aerodynamic
    </TextLayout>
    <PhotoGallery photos={[{
      src: "../../../wind/minifigure.png",
      width: 1920,
      height: 1079
    }, {
      src: "../../../wind/poster.png",
      width: 596,
      height: 843
    }]} mdxType="PhotoGallery" />
    <PhotoGallery photos={[{
      src: "../../../wind/iteration1.JPG",
      width: 4032,
      height: 3024
    }, {
      src: "../../../wind/iteration2.JPG",
      width: 4032,
      height: 3024
    }, {
      src: "../../../wind/iteration3.png",
      width: 3823,
      height: 2151
    }]} mdxType="PhotoGallery" />
    <PhotoGallery photos={[{
      src: "../../../wind/digital-sketching.png",
      width: 2732,
      height: 2048
    }, {
      src: "../../../wind/lego house 35.jpg",
      width: 1920,
      height: 1440
    }]} mdxType="PhotoGallery" />
    <PhotoGallery photos={[{
      src: "../../../wind/lego house.jpg",
      width: 1920,
      height: 1440
    }, {
      src: "../../../wind/lego house 2.jpg",
      width: 1920,
      height: 1440
    }, {
      src: "../../../wind/lego house 3.jpeg",
      width: 1920,
      height: 1440
    }, {
      src: "../../../wind/lego house 5.jpeg",
      width: 1920,
      height: 1440
    }]} mdxType="PhotoGallery" />
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/xWhQBq1fHEk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <TextLayout mdxType="TextLayout">
      <Title mdxType="Title">
  <h4>Introduction</h4>
      </Title>
      <Text mdxType="Text">
  After a field trip to Lego House in Billund, Denmark, I chose their physical
  outdoor zone because I am interested in outdoor play, and also Lego house's
  outdoor zone was under development.
      </Text>
      <img src="../../../wind/whitezone.png" />
      <i>The outdoor zone I chose</i>
      <Title mdxType="Title">
  <h4>Design Ideas</h4>
      </Title>
      <h5>Play with Contrast: a play experience activated by bad weather</h5>
      <Text mdxType="Text">
  In Billund, the rain and wind are very common. I want to design a play
  experience that turns the negative weather to positivity and implicitly
  teaches children shift the way of thinking.
      </Text>
      <h5>Can we play when it is rainy?</h5>
      <img src="../../../wind/rain.png" />
      <i>
  A canvas, constructed of special lego bricks, plays music when hit by the rain
  flow
      </i>
      <br />
      <br />
      <h5>Can we play when it is windy?</h5>
      <img src="../../../wind/wind.png" />
      <i>
  A game to compete for the highest score of parachute landing your minifigure
      </i>
      <Title mdxType="Title">
  <h4>Play Theory: Learning through Play</h4>
      </Title>
      <img src="../../../wind/play-experience.png" />
      <i>
  Expected behaviour coding with
  <a href="https://learningthroughplay.com/explore-the-research/the-scientific-case-for-learning-through-play">
    {" "}
    {""}learning through play tool
  </a> from Lego Foundation
      </i>
      <Text mdxType="Text">
  After discussion with designers from Lego House and Lego Foudndation, I
  decided on the wind concept because of its uniqueness and better expected
  playful learning outcome. The children are expected to be "I am enthusiastic
  about trying this again", "I am invested", "I am deliberate about the changes
  i make", "I am developing my own understanding" and "I cooperate with others
  to initiate new play experience".
      </Text>
      <Title mdxType="Title">
  <h4>Play Theory: Playful Tension</h4>
      </Title>
      <img src="../../../wind/tension.png" />
      <i>The orderly and unruly of ride with wind play experience</i>
      <Text mdxType="Text">
  Playful tension is a theoretical concept of play design, developed by Jess
  Uhre Rahbek, that bridges the gap between play theory and play design
  practice. To assist the player in achieving playful tension, the play needs to
  bring the orderly and the unruly together.
      </Text>
      <Title mdxType="Title">
  <h4>Hang Glider Design</h4>
      </Title>
      <Text mdxType="Text">
  It is impossible to develop the whole play experience within the time frame.
  So I focused on developing a hang glider because the flying is the key part of
  the experience.
      </Text>
      <h5>1st Iteration</h5>
      <img src="../../../wind/iteration1.JPG" />
      <i>Basic structure of a hang glider</i>
      <Text mdxType="Text"></Text>
      <h5>3rd Iteration</h5>
      <img src="../../../wind/iteration2.JPG" />
      <i>
  Improved aerodynamic structure, wireless remote control, and improved
  materials: basal wood frame and paper wing
      </i>
      <Text mdxType="Text"></Text>
      <h5>6th Iteration</h5>
      <img src="../../../wind/iteration3.png" />
      <i>
  Added propeller for longer flying experience, Improved frame material for
  robustness, Duplo brick container for electronics, More colour combinations
      </i>
      <Title mdxType="Title">
  <h5>Testings with hang glider</h5>
      </Title>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/FpVJhRfmV2g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <Text mdxType="Text">I tested various times both indoor and outdoor.</Text>
      <Title mdxType="Title">
  <h4>Demo and Feedback</h4>
  <h5>With Lego designers</h5>
      </Title>
      <img src="../../../wind/demo2.jpeg" />
      <Text mdxType="Text">
  At the end of the project, I presented my prototype to 10 designers from Lego
  House and Lego Foundation and received feedbacks from different perspectives,
  like business feasibility, local legislation, design process, space management
  and etc.
      </Text>
      <Gap margin={100} mdxType="Gap" />
    </TextLayout>
    <hr />
    <h5>{`References:`}</h5>
    <div>
  Rahbek, J. U. (2020). Designing for Playful Tension. In H. M. Skovbjerg, & S.
  K. Gudiksen (Eds.), Framing Play Design: A Hands-on Guide for Designers,
  Learners & Innovators (pp. 63). BIS-Verlag.
    </div>
    <br />
    <div>
  The Lego Foundation. (2020, August 20). Learning Through Play Experience Tool:
  Zooming in on the five characteristics of Learning through Play. Learning
  Through Play.
  https://learningthroughplay.com/learning-through-play-experience-tool
    </div>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      